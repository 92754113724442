import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { HotelRawData, HotelState } from './hotel.types';

const initialState: HotelState = {
  name: '',
  address: '',
  link: '',
  text: '',
  images: [],

  ui: {
    hotelIsOpen: false,
  },
};

export const hotelSlice = createSlice({
  name: 'hotel',
  initialState,
  reducers: {
    openHotel: (state, { payload }: PayloadAction<HotelRawData>) => {
      state.name = payload.name;
      state.address = payload.address;
      state.link = payload.link;
      state.text = payload.text;
      state.link = payload.link;
      state.images = payload.images.split(',');
      state.ui.hotelIsOpen = true;
    },
    closeHotel: (state) => {
      state.ui.hotelIsOpen = false;
    },
  },
});

export const { openHotel, closeHotel } = hotelSlice.actions;

export const selectHotel = (state: RootState) => state.hotel;

export const hotelReducer = hotelSlice.reducer;
