import { ThanksBodyProps } from './ThanksBody.props';
import { CloseButton } from 'components/elements';
import styles from './ThanksBody.module.scss';

export const ThanksBody = ({ onClose, type }: ThanksBodyProps): JSX.Element => {
  return (
    <div className={styles.module}>
      <img className={styles.bg} src="/build/images/bg-common.png" alt="bg" />
      <CloseButton className={styles.close} onClick={onClose} />
      <h3 className={styles.title}>
        {type === 'request'
          ? 'Спасибо, заявка отправлена!'
          : 'Спасибо, Ваш отзыв отправлен!'}
      </h3>
      <p className={styles.subtitle}>
        {type === 'request'
          ? 'Мы свяжемся с вами в ближайшее время!'
          : 'В ближайшее время он будет добавлен на сайт!'}
      </p>
    </div>
  );
};
