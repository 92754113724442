import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { closeHotel, selectHotel } from 'store/hotel/hotel.slice';
import { CloseButton } from 'components/elements';
import styles from './HotelBody.module.scss';
import { useEffect } from 'react';

Swiper.use([Navigation, Pagination]);

export const HotelBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const hotel = useSelector(selectHotel);
  const { name, address, link, text, images } = hotel;

  useEffect(() => {
    const slider = new Swiper(
      document.querySelector<HTMLElement>(`.${styles.slider}  .swiper`)!,
      {
        slidesPerView: 1,
        navigation: {
          nextEl: `.${styles.next}`,
          prevEl: `.${styles.prev}`,
        },
      },
    );

    GLightbox({
      selector: `.${styles.slide}`,
      loop: true,
    });

    return () => slider.destroy();
  }, []);

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeHotel())}
      />

      <h3 className={styles.name}>{name}</h3>

      <p className={styles.address}>{address}</p>

      <a className={styles.link} href={link} target="_blank" rel="noreferrer">
        Подробнее
      </a>

      <div className={styles.block}>
        <p className={styles.text}>{text}</p>

        <div className={styles.slider}>
          <div className="swiper">
            <div className="swiper-wrapper">
              {images.map((image) => (
                <div key={image} className="swiper-slide">
                  <a className={styles.slide} href={image}>
                    <img src={image} alt={name} />
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.nav}>
            <button className={styles.prev}></button>
            <button className={styles.next}></button>
          </div>
        </div>
      </div>
    </div>
  );
};
