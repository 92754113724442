import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { App } from './App';
// import reportWebVitals from './reportWebVitals';

import header from './scripts/header';
import screen from './scripts/screen';
import about from './scripts/about';
import rooms from './scripts/rooms';
import nutrition from './scripts/nutrition';
import beach from './scripts/beach';
import calculation from './scripts/calculation';
import reviews from './scripts/reviews';
import question from './scripts/question';
import hotels from './scripts/hotels';
import vacancy from './scripts/vacancy';

import './styles/index.scss';

const root = document.getElementById('root');
if (root) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    root,
  );
}

header.init();
screen.init();
about.init();
rooms.init();
nutrition.init();
beach.init();
calculation.init();
reviews.init();
question.init();
hotels.init();
vacancy.init();

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
