import { useRef } from 'react';
import cn from 'classnames';
import styles from './Babies.module.scss';
import { BabiesProps } from './Babies.props';
import { Portal } from 'components/other';
import { Dropdown } from 'components/layout';

import { ageOptions } from 'utils/constants/ageOptions.constants';
import { Select } from 'components/forms/Select/Select';

export const Babies = ({
  isOpen,
  onClick,
  onOutsideOrConfirmClick,
  onSetBaby,
  babies,
  className,
}: BabiesProps): JSX.Element => {
  const faceRef = useRef<HTMLDivElement>(null);

  const moduleStyles = cn(styles.module, className);

  return (
    <>
      <div className={moduleStyles} ref={faceRef} onClick={onClick}>
        <strong>Возраст детей</strong>
        <span>
          {babies.filter((_, idx) => idx < 5).join(', ')}
          {babies.length > 5 && ', ...'}
        </span>
      </div>
      {isOpen && (
        <Portal>
          <Dropdown
            faceRef={faceRef}
            onOutsideClick={onOutsideOrConfirmClick}
            position="bottomLeft"
          >
            <div className={styles.dropdown}>
              <div className={styles.list}>
                {babies.map((item, idx) => (
                  <div className={styles.item} key={idx}>
                    <span className={styles.name}>Ребенок {idx + 1}</span>
                    <Select
                      className={styles.select}
                      options={ageOptions}
                      paddingLeft={0}
                      value={item}
                      onChange={(event) => onSetBaby(idx, +event.target.value)}
                    />
                  </div>
                ))}
              </div>
              <button
                className={styles.button}
                onClick={onOutsideOrConfirmClick}
              >
                <span>Готово</span>
              </button>
            </div>
          </Dropdown>
        </Portal>
      )}
    </>
  );
};
