import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ReviewState } from './review.types';

const initialState: ReviewState = {
  text: '',
  name: '',
  grade: '',
  ui: {
    reviewIsOpen: false,
  },
};

export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    openReview: (
      state,
      { payload }: PayloadAction<{ text: string; name: string; grade: string }>
    ) => {
      state.text = payload.text;
      state.name = payload.name;
      state.grade = payload.grade;
      state.ui.reviewIsOpen = true;
    },
    closeReview: (state) => {
      state.ui.reviewIsOpen = false;
    },
  },
});

export const { openReview, closeReview } = reviewSlice.actions;

export const selectReview = (state: RootState) => state.review;

export const reviewReducer = reviewSlice.reducer;
