import { Policy } from 'components/elements';
import { TextField } from 'components/forms';
import { FormEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { openTextModal, openThanks } from 'store/app/app.slice';
import {
  clearQuestion,
  selectQuestion,
  setQuestionName,
  setQuestionPhone,
  toggleQuestionPolicy,
} from 'store/question/question.slice';
import styles from './Question.module.scss';

declare const ym: any;
declare const gtag: any;
declare const _tmr: any;

export const Question = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const question = useSelector(selectQuestion);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    ym(86947012, 'reachGoal', 'kick');
    gtag('event', 'submit', { event_category: 'form' });
    _tmr.push({ id: '3253066', type: 'reachGoal', goal: 'arrows' });

    setTimeout(() => dispatch(openThanks('request')), 300);

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'Вопрос',
        name: question.name,
        phone: question.phone,
      }),
    });

    dispatch(clearQuestion());
  };

  return (
    <form className={styles.module} onSubmit={handleSubmit}>
      <TextField
        className={styles.name}
        placeholder="Имя"
        value={question.name}
        onChange={(event) => dispatch(setQuestionName(event.target.value))}
      />
      <TextField
        className={styles.phone}
        placeholder="Телефон"
        type="tel"
        pattern="^\+?[0-9()\- ]+"
        required
        value={question.phone}
        onChange={(event) => dispatch(setQuestionPhone(event.target.value))}
      />
      <Policy
        className={styles.policy}
        checked={question.policyIsChecked}
        onChange={() => dispatch(toggleQuestionPolicy())}
        onClick={() => dispatch(openTextModal('policy'))}
        isInverted
      />
      <button className={styles.button}>Заказать звонок</button>
    </form>
  );
};
