import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { closeHotel, selectHotel } from 'store/hotel/hotel.slice';
import { Portal, Transition } from 'components/other';
import { Modal } from 'components/layout';
import { HotelBody } from './HotelBody/HotelBody';

export const Hotel = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const hotel = useSelector(selectHotel);

  return (
    <Transition isActive={hotel.ui.hotelIsOpen} timeout={300}>
      {(transitionStyles) => (
        <Portal withBlocking>
          <Modal
            onOverlayClick={() => dispatch(closeHotel())}
            className={transitionStyles}
          >
            <HotelBody />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
