import DayPicker, { DayModifiers } from 'react-day-picker';
import cn from 'classnames';
import { CalendarProps } from './Calendar.props';
import styles from './Calendar.module.scss';
import { localization } from './localization';

export const Calendar = ({
  onDayClick,
  disabledDaysBefore,
  disabledDaysAfter,
  className,
}: CalendarProps): JSX.Element => {
  const moduleStyles = cn(styles.module, className);

  return (
    <div className={moduleStyles}>
      <DayPicker
        locale="ru"
        months={localization.months}
        weekdaysLong={localization.weekdaysLong}
        weekdaysShort={localization.weekdaysShort}
        labels={localization.labels}
        firstDayOfWeek={1}
        month={disabledDaysBefore}
        disabledDays={{ before: disabledDaysBefore, after: disabledDaysAfter }}
        onDayClick={(day: Date, modifiers: DayModifiers) => {
          if (modifiers.disabled) {
            return;
          }
          onDayClick(day);
        }}
      />
    </div>
  );
};
