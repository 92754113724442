import { FormEventHandler } from 'react';
import Slider from 'rc-slider';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { TextArea, TextField } from 'components/forms';
import { CloseButton, Policy } from 'components/elements';
import styles from './AddReviewBody.module.scss';
import { openTextModal, openThanks } from 'store/app/app.slice';
import {
  clearAddReview,
  closeAddReview,
  selectAddReview,
  setAddReviewCity,
  setAddReviewEmail,
  setAddReviewGrade,
  setAddReviewName,
  setAddReviewText,
  toggleAddReviewPolicy,
} from 'store/addReview/addReview.slice';

export const AddReviewBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const addReview = useSelector(selectAddReview);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    dispatch(closeAddReview());
    setTimeout(() => dispatch(openThanks('addReview')), 300);

    await fetch('/ajax/addReview.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grade: addReview.grade,
        name: addReview.name,
        email: addReview.email,
        city: addReview.city,
        text: addReview.text,
      }),
    });

    dispatch(clearAddReview());
  };

  return (
    <div className={styles.module}>
      <img className={styles.bg} src="/build/images/bg-common.png" alt="bg" />
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeAddReview())}
      />
      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>Оставить отзыв</h2>

        <div className={styles.grade}>
          <p className={styles.gradeTitle}>Ваша оценка</p>
          <div className={styles.slider}>
            <Slider
              min={1}
              max={5}
              defaultValue={addReview.grade}
              marks={{
                1: 1,
                2: 2,
                3: 3,
                4: 4,
                5: 5,
              }}
              step={null}
              onChange={(value) => dispatch(setAddReviewGrade(value))}
            />
          </div>
        </div>

        <TextField
          className={styles.name}
          placeholder="Имя"
          required
          value={addReview.name}
          onChange={(event) => dispatch(setAddReviewName(event.target.value))}
        />
        <TextField
          className={styles.email}
          placeholder="E-mail"
          required
          type="email"
          value={addReview.email}
          onChange={(event) => dispatch(setAddReviewEmail(event.target.value))}
        />
        <TextField
          className={styles.city}
          placeholder="Город"
          required
          value={addReview.city}
          onChange={(event) => dispatch(setAddReviewCity(event.target.value))}
        />
        <TextArea
          className={styles.text}
          placeholder="Ваш отзыв"
          required
          value={addReview.text}
          onChange={(event) => dispatch(setAddReviewText(event.target.value))}
        />

        <Policy
          className={styles.policy}
          checked={addReview.policyIsChecked}
          onChange={() => dispatch(toggleAddReviewPolicy())}
          onClick={() => dispatch(openTextModal('policy'))}
        />
        <button className={styles.button}>Отправить</button>
      </form>
    </div>
  );
};
