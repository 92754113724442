import GLightbox from 'glightbox';

const initVideo = () => {
  const videoGallery = GLightbox({
    elements: [
      {
        href: 'https://www.youtube.com/watch?v=NFkxEW3QsbU&ab_channel=GSGROUP',
        type: 'video',
        source: 'youtube', //vimeo, youtube or local
        width: 1200,
      },
    ],
    autoplayVideos: true,
  });

  const image = document.querySelector('.screen__image');
  image?.addEventListener('click', () => {
    videoGallery.open();
  });
};

const screen = {
  init: () => {
    initVideo();
  },
};

export default screen;
