import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.reviews__slider .swiper', {
    loop: true,
    spaceBetween: 10,
    // centeredSlides: true,
    // loopedSlides: 10,

    navigation: {
      nextEl: '.reviews__next',
      prevEl: '.reviews__prev',
    },

    // pagination: {
    //   el: '.reviews__pagination',
    //   clickable: true,
    // },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });
};

const initReviewsHeight = () => {
  window.addEventListener('load', () => {
    const items = document.querySelectorAll<HTMLDivElement>('.reviews__slide');

    let height = 152;
    // let height = 240;
    // if (window.innerWidth >= 768) {
    //   height = 240;
    // }

    items.forEach((item) => {
      const p = item.querySelector<HTMLParagraphElement>('.reviews__text');
      if (p!.offsetHeight > height) {
        item.classList.add('reviews__slide--cut');
      }
    });
  });
};

const reviews = {
  init: () => {
    initSlider();
    initReviewsHeight();
  },
};

export default reviews;
