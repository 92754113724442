import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { Quiz } from 'views/Quiz';

const initQuiz = () => {
  const quiz = document.getElementById('quiz');
  if (!quiz) {
    return;
  }

  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <Quiz />
      </Provider>
    </StrictMode>,
    quiz,
  );
};

const about = {
  init: () => {
    initQuiz();
  },
};

export default about;
