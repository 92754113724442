import { ReviewBodyProps } from './ReviewBody.props';
import { CloseButton } from 'components/elements';
import styles from './ReviewBody.module.scss';

export const ReviewBody = ({
  text,
  name,
  grade,
  onClose,
}: ReviewBodyProps): JSX.Element => {
  return (
    <div className={styles.module}>
      <img className={styles.bg} src="/build/images/bg-common.png" alt="bg" />
      <CloseButton className={styles.close} onClick={onClose} />
      <p className={styles.text}>{text}</p>
      <p className={styles.name}>{name}</p>
      <p className={styles.grade}>оценка: {grade}</p>
    </div>
  );
};
