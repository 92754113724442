import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { Question } from 'views/Question';

const initQuestion = () => {
  const question = document.getElementById('question');
  if (!question) {
    return;
  }

  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <Question />
      </Provider>
    </StrictMode>,
    question,
  );
};

const question = {
  init: () => {
    initQuestion();
  },
};

export default question;
