import { Policy } from 'components/elements';
import { TextField } from 'components/forms';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { openTextModal } from 'store/app/app.slice';
import {
  selectQuiz,
  setQuizName,
  setQuizPhone,
  toggleQuizPolicy,
} from 'store/quiz/quiz.slice';
import styles from './QuizThree.module.scss';

export const QuizThree = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useSelector(selectQuiz);

  return (
    <div className={styles.module}>
      <TextField
        className={styles.name}
        placeholder="Имя"
        value={quiz.name}
        onChange={(event) => dispatch(setQuizName(event.target.value))}
      />
      <TextField
        className={styles.phone}
        placeholder="Телефон"
        type="tel"
        pattern="^\+?[0-9()\- ]+"
        required
        value={quiz.phone}
        onChange={(event) => dispatch(setQuizPhone(event.target.value))}
      />
      <Policy
        className={styles.policy}
        checked={quiz.policyIsChecked}
        onChange={() => dispatch(toggleQuizPolicy())}
        onClick={() => dispatch(openTextModal('policy'))}
      />
    </div>
  );
};
